<template>
    <div>
        <div id="prepayAnalysis"></div>
    </div>
</template>

<script>
import * as echarts from 'echarts';
let eChartsInstall;
export default {
    props:['makeAnalyse'],
    data(){
        return {
            isListener:false
        }
    },
    mounted() {
        setTimeout(() => {
            this.onChartDraw();
        }, 0);
    },
    methods: {
        onChartDraw() {
            var chartDom = document.getElementById('prepayAnalysis');
            echarts.init(chartDom).dispose();
            eChartsInstall = echarts.init(chartDom);
            let xAxisData = []
            for(let key in this.makeAnalyse){
                xAxisData.push({value:this.makeAnalyse[key],name:key})
            }

            let options = {
                tooltip: {},
                grid: {
                    bottom: 50
                },
                series: [
                    {
                        type: 'pie',
                        radius: ['60%','80%'],
                        itemStyle: {
                           normal: {
                                color: function (colors) {
                                    var colorList = [
                                            '#68bbc4',
                                            '#58a55c',
                                            '#5894ff',
                                            '#f2bd42'
                                            ];
                                    return colorList[colors.dataIndex];
                                 }
                             }
                        },
                        data: xAxisData,
                        label: {
                            show: true,
                            fontSize: 14,
                            formatter: function (arg) {
                            return arg.name +'\n'+ arg.value
                           }
                        }
                    }
                ]
            }
            // eChartsInstall.on('brushSelected', function (params) {
            //     var brushed = [];
            //     var brushComponent = params.batch[0];
            //     for (var sIdx = 0; sIdx < brushComponent?.selected?.length; sIdx++) {
            //         var rawIndices = brushComponent?.selected[sIdx].dataIndex;
            //         brushed.push('[Series ' + sIdx + '] ' + rawIndices.join(', '));
            //     }
            //     eChartsInstall.setOption({
            //         title: {
            //             backgroundColor: '#333',
            //             text: 'SELECTED DATA INDICES: \n' + brushed.join('\n'),
            //             bottom: 0,
            //             right: '10%',
            //             width: 100,
            //             textStyle: {
            //                 fontSize: 12,
            //                 color: '#fff'
            //             }
            //         }
            //     });
            // });
            this.$nextTick(() => {
                eChartsInstall.setOption(options);
                this.resize();
            })
        },
        resize() {
            if (this.isListener) return;
            window.addEventListener('resize', (e) => {
                if (this.timeOut) {
                    clearTimeout(this.timeOut);
                }
                this.timeOut = setTimeout(() => {
                    this.timeOut = null;
                    eChartsInstall.resize();
                }, 300);
            }, true);
            this.isListener = true;
        },
    }
}
</script>

<style scoped>
#prepayAnalysis {
    margin-top: 10px;
    width: 100%;
    height: 340px;
}
</style>
<template>
	<div class="ui-phase">
		<a-table
            :columns="columns"
            :data-source="stageAnalyse"
            :loading="loading"
			:pagination="false"
			:scroll="{x:500}"
			bordered
        >
            <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'stage'">
				<span v-if="record.stage===1">建设用地储备</span>
				<span v-else-if="record.stage===2">项目立项</span>
				<span v-else-if="record.stage===3">项目招标</span>
				<span v-else-if="record.stage===4">项目施工</span>
				<span v-else-if="record.stage===5">竣工验收</span>
				<span v-else>合计</span>
			</template>
			<template v-if="column.key === 'num'">
				{{record.num ? record.num : "\\"}}
			</template>
			<template v-if="column.key === 'invest'">
				{{record.invest ? record.invest : "\\"}}
			</template>
			<template v-if="column.key === 'schedule'">
				{{record.schedule ? record.schedule : "\\"}}
			</template>
			<template v-if="column.key === 'prepay'">
				{{record.prepay ? record.prepay : "\\"}}
			</template>
            </template>
        </a-table>
	</div>
</template>

<script>
	import cTable from '@/components/cTable/index.vue';
	export default {
		components:{cTable},
		props:['stageAnalyse'],
		data(){
			return{
				stageAnalyseList: []
			}
		},
		mounted() {

		},
		data(){
			return{
		        columns: [
					{
					title: '项目阶段',
					align: 'center',
					dataIndex: 'stage',
					key: 'stage',
					width: 100
				}, {
					title: '项目数',
					align: 'center',
					dataIndex: 'num',
					key: 'num',
					width: 100
				}, {
					title: '总投资金额',
					align: 'center',
					dataIndex: 'invest',
					key: 'invest',
					width: 100
				}, {
					title: '工程完工量',
					align: 'center',
					dataIndex: 'schedule',
					key: 'schedule',
					width: 100
				}, {
					title: '已拨付数',
					align: 'center',
					dataIndex: 'prepay',
					key: 'prepay',
					width: 100
				}]
			}
		},
		methods: {

		}
	}
</script>

<style scoped>
.ui-phase{
	margin-top: 10px;
}
</style>
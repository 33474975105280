<template>
<a-spin :spinning="loading">
	<div>
		<a-table
            :columns="columns"
			:pagination="pagination"
            :data-source="unitAnalyseList"
			:scroll="{y:380}"
			bordered
			@change="onChange"
        >
            <template #bodyCell="{ column, record }">
				<template v-if="column.key === 'fixedPrepay'">
					<span v-if="record.fixed">{{record.fixed.prepay?record.fixed.prepay:"\\"}}</span>
				</template>
				<template v-if="column.key === 'fixedWaitpay'">
					<span v-if="record.fixed">{{record.fixed.waitpay?record.fixed.waitpay:"\\"}}</span>
				</template>
				<template v-if="column.key === 'fixedContract'">
					<span v-if="record.fixed">{{record.fixed.contract?record.fixed.contract:"\\"}}</span>
				</template>
				<template v-if="column.key === 'fixedIncr'">
					<span v-if="record.fixed">{{record.fixed.incr?record.fixed.incr:"\\"}}</span>
				</template>
				<template v-if="column.key === 'basicPrepay'">
					<span v-if="record.basic">{{record.basic.prepay?record.basic.prepay:"\\"}}</span>
				</template>
				<template v-if="column.key === 'basicWaitpay'">
					<span v-if="record.basic">{{record.basic.waitpay?record.basic.waitpay:"\\"}}</span>
				</template>
				<template v-if="column.key === 'basicContract'">
					<span v-if="record.basic">{{record.basic.contract?record.basic.contract:"\\"}}</span>
				</template>
				<template v-if="column.key === 'basicIncr'">
					<span v-if="record.basic">{{record.basic.incr?record.basic.incr:"\\"}}</span>
				</template>
				<template v-if="column.key === 'expensesPrepay'">
					<span v-if="record.expenses">{{record.expenses.prepay?record.expenses.prepay:"\\"}}</span>
				</template>
				<template v-if="column.key === 'expensesWaitpay'">
					<span v-if="record.expenses">{{record.expenses.waitpay?record.expenses.waitpay:"\\"}}</span>
				</template>
				<template v-if="column.key === 'expensesContract'">
					<span v-if="record.expenses">{{record.expenses.contract?record.expenses.contract:"\\"}}</span>
				</template>
				<template v-if="column.key === 'expensesIncr'">
					<span v-if="record.expenses">{{record.expenses.incr?record.expenses.incr:"\\"}}</span>
				</template>
				<template v-if="column.key === 'subtotal'">
					<span>{{record.subtotal?record.subtotal:"\\"}}</span>
				</template>
            </template>
        </a-table>
	</div>
</a-spin>
</template>

<script>
import cTable from '@/components/cTable/index.vue';
import {getProjectUnitAnalyse} from '@/service/modules/project.js'
export default {
	components:{cTable},
	data(){
		return{
			loading: false,
			unitAnalyseList: [],
			pagination: {
				showSizeChanger: true,
				showQuickJumper: true,
				size: "middle",
				showTotal: (total) => {
					return "共 " + total + " 条";
				},
				pageSizeOptions: ['10', '20', '50', '100', '500'],
				total: 0,
				current: 1,
				pageSize: 10,
				onChange: (page) => {
					this.pagination.current = page;
					this.getProjectDataUnitAnalyse()
				},
				onShowSizeChange: (page, pageSize) => {
					this.pagination.current = 1;
					this.pagination.pageSize = pageSize;
					this.getProjectDataUnitAnalyse()
				}
			},
			columns: [{
				title: '单位名称',
				dataIndex: 'name',
				align: 'center',
				key: 'name',
				fixed: 'left',
				width: 150
				}, {
				title: '固定资产项目',
				children: [{
					title: '已拨付数',
					dataIndex: 'prepay',
					key: 'fixedPrepay',
					align: 'center',
					width: 120,
					resizable: true,
					}, {
						title: '待拨付数',
						dataIndex: 'waitpay',
						key: 'fixedWaitpay',
						align: 'center',
						width: 120,
						resizable: true,
					}, {
						title: '合同总金额',
						dataIndex: 'contract',
						key: 'fixedContract',
						align: 'center',
						width: 120,
						resizable: true,
					}, {
						title: '增量金额',
						dataIndex: 'incr',
						key: 'fixedIncr',
						align: 'center',
						width: 120,
						resizable: true,
					}]
				}, {
					title: '公共基础设施项目',
					align: 'center',
					width: 180,
					resizable: true,
					children: [{
					title: '已拨付数',
					dataIndex: 'prepay',
					key: 'basicPrepay',
					align: 'center',
					width: 120,
					resizable: true
					}, {
						title: '待拨付数',
						dataIndex: 'waitpay',
						key: 'basicWaitpay',
						align: 'center',
						width: 120,
						resizable: true
					}, {
						title: '合同总金额',
						dataIndex: 'contract',
						key: 'basicContract',
						align: 'center',
						width: 120,
						resizable: true
					}, {
						title: '增量金额',
						dataIndex: 'incr',
						key: 'basicIncr',
						align: 'center',
						width: 120,
						resizable: true
					}]
				}, {
					title: '费用支出项目',
					children: [{
					title: '已拨付数',
					dataIndex: 'prepay',
					key: 'expensesPrepay',
					align: 'center',
					width: 120,
					resizable: true,
					}, {
						title: '待拨付数',
						dataIndex: 'waitpay',
						key: 'expensesWaitpay',
						align: 'center',
						width: 120,
						resizable: true
					}, {
						title: '合同总金额',
						dataIndex: 'contract',
						key: 'expensesContract',
						align: 'center',
						width: 120,
						resizable: true
					}, {
						title: '增量金额',
						dataIndex: 'incr',
						key: 'expensesIncr',
						align: 'center',
						width: 120,
						resizable: true
					}]
				}, {
					title: '小计',
					dataIndex: 'subtotal',
					align: 'center',
					key: 'subtotal',
					width: 120
			}]
		}
	},
	props:{
		searchData:{
			type:String,
			default:''
		}
	},
	watch:{
		searchData:{
			handler(newVal,oldVal){
				if(newVal != oldVal){
					this.getProjectDataUnitAnalyse();
				}
			},
			immediate:true

		}
	},
	mounted() {
		setTimeout(() => {
			this.getProjectDataUnitAnalyse()
		}, 0);
	},
	methods: {
		async getProjectDataUnitAnalyse(){
			this.loading = true;
			try {
				let ret = await getProjectUnitAnalyse({
					page: this.pagination.current,
					pageSize: this.pagination.pageSize,
					name:this.searchData
				})
				this.loading = false;
				if (ret.code === 200) {
					this.unitAnalyseList = []
					this.pagination.total = ret.data.totalCount
					this.unitAnalyseList.push(...ret.data.list)
				}
			} catch (e) {
				this.loading = false;
			}
		}

	}
}
</script>

<style scoped>
</style>
<template>
    <div>
        <div id="projectType"></div>
    </div>
</template>

<script>
import * as echarts from 'echarts';
let eChartsInstall;
export default {
    props:['typeAnalyse'],
    data(){
        return {
            isListener:false
        }
    },
    mounted() {
        setTimeout(() => {
            this.onChartDraw()            
        }, 0);
    },
    methods: {
        onChartDraw() {
            var chartDom = document.getElementById('projectType');
            echarts.init(chartDom).dispose();
            eChartsInstall = echarts.init(chartDom);
            let xAxisData = [];
            let prepayData = [];
            let waitpayData = [];
            // let maxNumber = 0
            this.typeAnalyse.forEach((item)=>{
                xAxisData.push(item.name)
                prepayData.push(item.prepay)
                waitpayData.push(item.waitpay)
                // if(item.prepay + item.waitpay > maxNumber){
                //     maxNumber = item.prepay + item.waitpay
                // }
            })
            let options = {
                legend: {
                    data: ['待拨付款', '已拨付款'],
                    left: '40%',
                    textStyle: {
                        fontSize: 14
                    }
                },
                tooltip: {},
                xAxis: {
                    data: xAxisData,
                    axisLine: { onZero: true },
                    splitLine: { show: false },
                    splitArea: { show: false },
                    axisLabel: {
                        textStyle: {
                        fontSize: 14,
                        },
                    },
                },
                yAxis: {
                    type: `value`,
                    axisLabel: {
                        formatter:function (value, index) {           
						 return value.toFixed(2);      
						}
                    },
                    // max: maxNumber
                },
                grid: {
                    left: 150,
                    bottom: 50
                },
                series: [
                    {
                        name: '待拨付款',
                        type: 'bar',
                        stack: 'one',
                        itemStyle: {
                            shadowBlur: 10,
                            shadowColor: 'rgba(0,0,0,0.3)',
                            color: '#5087ec'
                        },
                        data: waitpayData,
                        label: {
                            show: true,
                            color: '#ffffff'
                        }
                    },
                    {
                        name: '已拨付款',
                        type: 'bar',
                        stack: 'one',
                        itemStyle: {
                            shadowBlur: 10,
                            shadowColor: 'rgba(0,0,0,0.3)',
                            color: '#68bbc4'
                        },
                        data: prepayData,
                        label: {
                            show: true,
                            color: '#ffffff'
                        }
                    }
                ]
            }
            // eChartsInstall.on('brushSelected', function (params) {
            //     var brushed = [];
            //     var brushComponent = params.batch[0];
            //     for (var sIdx = 0; sIdx < brushComponent?.selected?.length; sIdx++) {
            //         var rawIndices = brushComponent?.selected[sIdx].dataIndex;
            //         brushed.push('[Series ' + sIdx + '] ' + rawIndices.join(', '));
            //     }
            //     eChartsInstall.setOption({
            //         title: {
            //             backgroundColor: '#333',
            //             text: 'SELECTED DATA INDICES: \n' + brushed.join('\n'),
            //             bottom: 0,
            //             right: '10%',
            //             width: 100,
            //             textStyle: {
            //                 fontSize: 12,
            //                 color: '#fff'
            //             }
            //         }
            //     });
            // });
            this.$nextTick(() => {
                eChartsInstall.setOption(options);
                this.resize();
            })
        },
        resize() {
            if (this.isListener) return;
            window.addEventListener('resize', (e) => {
                if (this.timeOut) {
                    clearTimeout(this.timeOut);
                }
                this.timeOut = setTimeout(() => {
                    this.timeOut = null;
                    eChartsInstall.resize();
                }, 300);
            }, true);
            this.isListener = true;
        },
    }
}
</script>

<style scoped>
#projectType {
    margin-top: 10px;
    width: 100%;
    height: 340px;
}
</style>
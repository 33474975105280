<template>
<a-spin :spinning="loading">
    <div>
        <div class="ui-dataPreview">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <div class="ui-titleStyle">
                <view>
                    <Icon icon="LineChartOutlined"></Icon>
                    数据总览
                </view>
                <div class="ui-unit">单位：万元</div>
            </div>
            <div class="ui-contentDetail">
                <div class="ui-detailAll">
                    <div class="ui-detailAll-box">
                        <div class="ui-text ui-textColor">项目总数</div>
                        <div class="ui-text ui-textBlack">{{overview.num}}<div class="ui-spanSize">个</div></div>
                    </div>
                    <div class="ui-detailPrice-box">
                        <div class="ui-text ui-textColor">项目总金额</div>
                        <div class="ui-text ui-textRed">{{overview.payment}}<div class="ui-spanSize">万元</div></div>
                    </div>
                </div>
                <div class="ui-detail">
                    <div class="ui-detail-itemBox">
                        <div class="ui-textBlack">{{overview.contract}}</div>
                        <div class="ui-textMinColor">施工合同额</div>
                    </div>
                    <div class="ui-detail-itemBox">
                        <div class="ui-textBlack">{{overview.schedule}}</div>
                        <div class="ui-textMinColor">工程完工量</div>
                    </div>
                    <div class="ui-detail-itemBox">
                        <div class="ui-textBlack">{{overview.prepay}}</div>
                        <div class="ui-textMinColor">已拨付数</div>
                    </div>
                    <div class="ui-detail-itemBox">
                        <div class="ui-textBlack">{{overview.waitpay}}</div>
                        <div class="ui-textMinColor">待拨款数</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="ui-centerBox">
            <div class="ui-analysis ui-centerBox-item">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <div class="ui-titleStyle">
                    <div>
                        <Icon icon="LineChartOutlined"></Icon>
                        项目类型分析
                    </div>
                    <div class="ui-unit">单位：万元</div>
                </div>
                <projectType v-if="typeAnalyse.length" :typeAnalyse="typeAnalyse"></projectType>
            </div>
            <div class="ui-analysis ui-centerBox-right">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <div class="ui-titleStyle">
                  <div>
                      <Icon icon="LineChartOutlined"></Icon>
                      项目资金分析
                  </div>
                  <div class="ui-unit">单位：万元</div>
                </div>
                <fundAnalysis v-if="fundAnalyse.length" :fundAnalyse="fundAnalyse"></fundAnalysis>
            </div>
        </div>
        <div class="ui-centerTable">
            <div class="ui-tableLeft ui-centerTable-item">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <div class="ui-titleStyle">
                    <div>
                        <Icon icon="LineChartOutlined"></Icon>
                        阶段项目分析
                    </div>
                    <div class="ui-unit">单位：个、万元</div>
                </div>
                 <phaseProject v-if="stageAnalyse.length" :stageAnalyse="stageAnalyse"></phaseProject>
            </div>
            <div class="ui-tableRight ui-centerTable-item">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <div class="ui-titleStyle">
                    <div>
                        <Icon icon="LineChartOutlined"></Icon>
                        已拨付数分析
                        </div>
                    <div class="ui-unit">单位：万元</div>
                </div>
                <prepayAnalysis v-if="JSON.stringify(makeAnalyse)!=='{}'" :makeAnalyse="makeAnalyse"></prepayAnalysis>
            </div>
        </div>
        <div class="ui-time-box">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <div class="ui-titleStyle">
               <div class="ui-flex">
                    <Icon icon="LineChartOutlined"></Icon>
                    <div class="ui-title">单位资金拨付情况</div>
                    <a-input-search
                        v-model:value="value"
                        placeholder="输入单位名称"
                        enter-button="搜索"
                        size="large"
                        @search="onSearch"
                    />
               </div>
               <div class="ui-unit">单位：万元</div>
            </div>
            <div class="ui-time-content">
                    <ranking :unitAnalyse="unitAnalyse" :searchData="searchData"></ranking>
            </div>
        </div>
    </div>
</a-spin>
</template>
<script>
import efficiency from './components/efficiency.vue';
import inventoryStatistics from './components/inventoryStatistics.vue'
import { Icon } from '@/components/icon/icon.js';
import ranking from './components/ranking.vue';
import projectType from './components/projectType.vue'
import phaseProject from './components/phaseProject.vue'
import {getProjectAnalyse} from '@/service/modules/project.js'
import fundAnalysis from './components/fundAnalysis.vue';
import prepayAnalysis from './components/prepayAnalysis.vue';
export default {
    components:{Icon,projectType,efficiency,inventoryStatistics,ranking,phaseProject,fundAnalysis,prepayAnalysis},
    data(){
        return {
            stageAnalyse: [],
            unitAnalyse: [],
            loading: false,
            overview: {},
            typeAnalyse: [],
            fundAnalyse: [],
            makeAnalyse: {},
            value:'',
            searchData:''
        }
    },
    mounted(){
        this.getProjectDataAnalyse()
    },
    methods: {
        async getProjectDataAnalyse(){
            let totalNum = 0
            let totalInvest = 0
            let totalSchedule = 0
            let totalPrepay = 0
                this.loading = true;
                try {
                    let ret = await getProjectAnalyse({})
                    this.loading = false;
                    if (ret.code === 200) {
                        ret.data.stageAnalyse.forEach((item)=>{
                            totalNum += item.num
                            totalInvest += item.invest
                            totalSchedule += item.schedule
                            totalPrepay += item.prepay
                        })
                        this.overview = ret.data.overview
                        this.typeAnalyse = ret.data.typeAnalyse
                        this.fundAnalyse = ret.data.fundAnalyse
                        this.makeAnalyse = ret.data.makeAnalyse
                        this.stageAnalyse.push(...ret.data.stageAnalyse,{stage:6,num:totalNum,invest:totalInvest,schedule:totalSchedule,prepay:totalPrepay})
                    }
                } catch (e) {
                    console.log(e, 111)
                    //TODO handle the exception
                    this.loading = false;
                }
        },
        onSearch(e){
            // if(e){
            //     this.searchData = e;
            //     this.value = '';
            // }
            this.searchData = e;
            this.value = '';
        }
    }
}
</script>
<style scoped lang="less">
.ui-dataPreview{
    height:141px;
    border: 1px solid rgba(11, 70, 167, 1);
    padding:10px;
}
.ui-titleStyle{
    display: flex;
    justify-content: space-between;
    color:#0B46A7;
    font-size:20px;
    font-weight:600;
}
.ui-unit{
    color: #7e8088;
    font-size:16px;
}
.ui-contentDetail{
    display:flex;
    margin-top:10px;
}
.ui-detailAll{
    display:flex;
}
.ui-detailAll-box{
    width:150px;
    text-align:center;
}
.ui-spanSize{
    display: inline;
    font-size:15px;
}
.ui-detailPrice-box{
    width:200px;
    text-align:center;
}
.ui-textColor{
    color:#777777;
    font-size:18px;
}
.ui-textBlack{
    color:#101010;
    font-size:28px;
    font-weight:600;
}
.ui-textRed{
    color:#FF3A30;
    font-size:28px;
    font-weight:600;
}
.ui-text{
    padding:0;
    margin:0;
}
.ui-detail{
    margin-left: 200px;
    display:flex;
    width: 100%;
    justify-content: space-around;
}
.ui-detail-itemBox{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.ui-textMinColor{
    color:#777777;
    font-size:14px;
}
.ui-centerBox{
    display:flex;
    margin-top:14px;
    justify-content:space-between;
}
.ui-centerTable{
    display:flex;
    margin-top:14px;
}
.ui-analysis{
    flex:3;
    height:400px;
}
.ui-tableLeft{
    width: 60%;
    height:450px;
}
.ui-tableRight{
    margin-left: 20px;
    width: 40%;
    height:450px;
}
.ui-fund{
    flex:4;
    margin:0 20px;
    height:400px;
}
.ui-rate{
    flex:3;
    height:400px;
}
.ui-centerBox-item,.ui-centerTable-item{
    padding:10px;
    border: 1px solid rgba(11, 70, 167, 1);
}
.ui-centerBox-right{
    margin-left: 20px;
    padding:10px;
    border: 1px solid rgba(11, 70, 167, 1);
}
.ui-analysis-top{
    margin-top:80px;
    height:250px;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:space-evenly;
}

.ui-analysis-itembox{
    width:300px;
    height:45px;
    border:1px solid #3F7FD2;
    border-radius:40px;
    .ui-analysis-title{
        display:inline-block;
        height:45px;
        width:195px;
        background-color:#3F7FD2;
        font-size:20px;
        border-radius:40px;
        text-align:center;
        color:#FFFFFF;
        line-height:40px;
    }
    .ui-analysis-minTitle{
        font-size:16px;
    }
    .ui-analysis-num{
        font-size:20px;
        color:#3F7FD2;
        display:inline-block;
        margin-left:20px;
    }
}


.ui-rate-content{
    display:flex;
    justify-content:center;
    margin-top:20px;
}

.ui-time-box{
    height:630px;
    border: 1px solid rgba(11, 70, 167, 1);
    margin-top:15px;
    padding:10px;
}
.ui-time-content{
    margin-top: 10px;
}
.ui-dataPreview,.ui-analysis,.ui-tableRight,.ui-tableLeft,.ui-fund,.ui-rate,.ui-time-box{
    position: relative;
    >span:nth-child(1){
        position: absolute;
        left: -5px;
        top:-5px;
        width: 20px;
        height: 20px;
        border-top: solid 5px #4083E2;
        border-left: solid 5px #4083E2;
    }
    >span:nth-child(2){
        position: absolute;
        top: -5px;
        right:-5px;
        width: 20px;
        height: 20px;
        border-top: solid 5px #4083E2;
        border-right: solid 5px #4083E2;
    }
    >span:nth-child(3){
        position: absolute;
        left: -5px;
        bottom:-5px;
        width: 20px;
        height: 20px;
        border-left: solid 5px #4083E2;
        border-bottom: solid 5px #4083E2;
    }
    >span:nth-child(4){
        position: absolute;
        right: -5px;
        bottom:-5px;
        width: 20px;
        height: 20px;
        border-right: solid 5px #4083E2;
        border-bottom: solid 5px #4083E2;
    }
}
.ui-flex{
    display:flex;
    align-items:center;
}
.ui-title{
    width: 300px;
    margin-left:5px;
}
</style>